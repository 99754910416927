/** ComplianceExpertMainPage.js **/

import React, { useState, useEffect } from "react"
import { updateCaseInfo } from "../../services/notebook/notebookServices"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Box, CircularProgress } from "@mui/material"
import Sidebar from "../../components/SideBar/Sidebar"
import NewAlert from "./components/NewAlert"
import EmptyBox from "./components/EmptyBox"
import { useOutletContext } from "react-router-dom"
import styles from "./MainPage.module.scss"
import CaseReportDialog from "./components/CaseReportDialog"
import FilesAttachments from "./components/FilesAttachments"
import { uploadCSVReport } from "../../services/main/mainServices"
import KanbanBoard from "./components/CasesTableExpert/KanbanBoard"

const urgencyOptions = ["Low", "Medium", "High"]

const ComplianceExpertMainPage = () => {
    const [
        control,
        values,
        triggerFetchMainPageData,
        setTriggerFetchMainPageData,
        loadMainPageData,
        isInitialLoading,
        handleFinalizeReportStatus,
    ] = useOutletContext()

    const dispatch = useDispatch()
    const refreshAccessTokenCounter = useSelector((state) => state.refreshAccessTokenCounter)
    const currentCompanyId = useSelector((state) => state.companiesState.userCompanyId)

    const [openNewAlertPopup, setOpenNewAlertPopup] = useState(false)
    const [welcomeState, setWelcomeState] = useState(false)
    const [sections, setSections] = useState([])

    const [displayedReportId, setDisplayedReportId] = useState(null)
    const [caseReportDialogOpen, setCaseReportDialogOpen] = useState(false)
    const [auditTrailPreview, setAuditTrailPreview] = useState("<div/>")
    const [successEmailOpen, setSuccessEmailOpen] = useState(false)
    const [failEmailOpen, setFailEmailOpen] = useState(false)

    const [filesModalOpen, setFilesModalOpen] = useState(false)
    const [selectedFilesCaseId, setSelectedFilesCaseId] = useState(null)

    const openReportDialogById = (id) => {
        setDisplayedReportId(id)
        setCaseReportDialogOpen(true)
    }
    const openFilesModalById = (id) => {
        setSelectedFilesCaseId(id)
        setFilesModalOpen(true)
    }
    const closeFilesModal = () => {
        setFilesModalOpen(false)
        setSelectedFilesCaseId(null)
    }

    const getWelcomeSubText = () => {
        return (
            <>
                You currently don't have any report requests.{" "}
                <a
                    href="https://byvalue.retool.com/apps/fc82aadc-6061-11ef-a0cf-a38acd2495c6/Backoffice/EDD%20Expert%20Space"
                    target="_blank"
                    rel="noreferrer"
                >
                    Assign yourself a new EDD request
                </a>
            </>
        )
    }

    const updateCaseInfoMap = async (case_id, type, value) => {
        await updateCaseInfo(case_id, type, value, dispatch, refreshAccessTokenCounter)
    }

    useEffect(() => {
        if (
            !isInitialLoading &&
            (values?.data_collection?.length ?? 0) === 0 &&
            (values?.resolutions?.length ?? 0) === 0 &&
            (values?.final_review?.length ?? 0) === 0 &&
            (values?.done?.length ?? 0) === 0
        ) {
            setWelcomeState(true)
        } else {
            setWelcomeState(false)
        }

        setSections([
            {
                controllerName: "data_collection",
                headerTitle: "Data Collection",
                emptyText: "No data collection reports",
                cases: values?.data_collection || [],
            },
            {
                controllerName: "resolutions",
                headerTitle: "Resolutions",
                emptyText: "No reports in resolutions",
                cases: values?.resolutions || [],
            },
            {
                controllerName: "final_review",
                headerTitle: "Final Review",
                emptyText: "No reports waiting for final review",
                cases: values?.final_review || [],
            },
            {
                controllerName: "done",
                headerTitle: "Done",
                emptyText: "No completed reports yet",
                cases: values?.done || [],
            },
        ])
    }, [
        values?.data_collection,
        values?.resolutions,
        values?.final_review,
        values?.done,
        isInitialLoading,
    ])

    function getDoneItemById(values, id) {
        return (
            values.final_review.find((item) => item.id === id)?.report ||
            values.done.find((item) => item.id === id)?.report ||
            null
        )
    }

    const selectedCase =
        values?.data_collection?.find((item) => item.id === selectedFilesCaseId) ||
        values?.resolutions?.find((item) => item.id === selectedFilesCaseId) ||
        null
    const selectedCaseFiles = selectedCase?.files || []

    const handleUploadCSV = async (reportRequestId, csvJson) => {
        try {
            await uploadCSVReport(reportRequestId, csvJson, dispatch, refreshAccessTokenCounter)
            alert("Successfully uploaded CSV for card ID: " + reportRequestId)
            loadMainPageData()
        } catch (err) {
            console.error(err)
            alert("Error uploading CSV: " + err.message)
        }
    }

    return (
        <Box className={styles.mainPageContainer}>
            {/* Report Dialog */}
            {caseReportDialogOpen && (
                <CaseReportDialog
                    open={caseReportDialogOpen}
                    setClosed={() => setCaseReportDialogOpen(false)}
                    caseTitle={values.title}
                    auditTrailPreview={auditTrailPreview}
                    reportData={getDoneItemById(values, displayedReportId)}
                    displayedReportId={displayedReportId}
                    expert
                    handleFinalizeReportStatus={handleFinalizeReportStatus}
                />
            )}

            {/* Files Modal */}
            <FilesAttachments
                open={filesModalOpen}
                onClose={closeFilesModal}
                caseId={selectedFilesCaseId}
                files={selectedCaseFiles}
            />

            <NewAlert
                openNewAlertPopup={openNewAlertPopup}
                setOpenNewAlertPopup={setOpenNewAlertPopup}
                triggerFetchMainPageData={triggerFetchMainPageData}
                setTriggerFetchMainPageData={setTriggerFetchMainPageData}
                updateCaseInfoMap={updateCaseInfoMap}
                loadMainPageData={loadMainPageData}
            />
            <Sidebar />
            <Box className={styles.mainPageHeaderWrapper}>
                <Typography fontSize="24px" color="#000">
                    My Due Diligence Reports
                </Typography>
            </Box>
            {isInitialLoading ? (
                <Box className={styles.mainPageLoader}>
                    <CircularProgress />
                </Box>
            ) : welcomeState && !isInitialLoading ? (
                <EmptyBox mainText={"Welcome to Redstrings!"} subText={getWelcomeSubText()} />
            ) : (
                <KanbanBoard
                    sections={sections}
                    openReportDialogById={openReportDialogById}
                    openFilesModalById={openFilesModalById}
                    onUploadCSV={handleUploadCSV}
                />
            )}
        </Box>
    )
}

export default ComplianceExpertMainPage
